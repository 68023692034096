<template>
    <div class="wardinfowrapper" v-if="wardinfo">
        <div class="welcomewrapper">
          <h1>{{wardinfo.header.value}}</h1>

          <template v-for="(address, index) in wardinfo.addresses.value">

            <div 
              :key="index"
              class="addressItem">
                  <label for="">{{address.name}}</label>
            <div
                @click="
                    openMap('point', {
                        address: address.street_address.address,
                    })
                "
                class="address"
            >
                <span class="markericon">
                    <b-icon icon="geo-alt-fill"></b-icon>
                </span>
                <span class="theaddress"> {{address.street_address.address}} </span>

                <a href="">
                    <b-iconstack font-scale="1">
                        <b-icon stacked rotate="45" icon="square-fill"></b-icon>
                        <b-icon
                            stacked
                            icon="arrow90deg-right"
                            scale="0.65"
                        ></b-icon>
                    </b-iconstack>
                </a>
            </div>
            </div>
            
          </template>

          <!-- <label for="">Geneva Heights Stake Building</label>
          <div class="address">
              <span class="markericon">
                  <b-icon icon="geo-alt-fill"></b-icon>
              </span>
              <span class="theaddress"> 955 N 300 E St, Orem, UT 84057, United States </span>
              <a href="">
                  <b-iconstack font-scale="1">
                      <b-icon stacked rotate="45" icon="square-fill"></b-icon>
                      <b-icon
                          stacked
                          icon="arrow90deg-right"
                          scale="0.65"
                      ></b-icon>
                  </b-iconstack>
              </a>
          </div> -->
        </div>


        <div class="bishopricWrapper">
          <h3 class="sectionTitle">{{wardinfo.bishopric.value.title}}</h3>

            <b-row no-gutters>
                <template v-for="(bishop, index) in wardinfo.bishopric.value.contact_persons">
                   <b-col 
                    :key="index"
                    cols="4">
                      <div class="personCard">
                          <img 
                            v-if="bishop.profile_picture && bishop.profile_picture.url"
                            :src="bishop.profile_picture.url" alt="">

                          <b-icon 
                            v-else
                            icon="person-circle"></b-icon>

                          <div class="personCardPosition">
                              {{bishop.position}}
                          </div>
                          <div class="personCardName">
                              {{bishop.name}}
                          </div>
                      </div>
                  </b-col>
                </template>
            </b-row>
        </div>

        <b-btn 
          class="bishopMessageBtn"
          block 
          @click="$router.push({name :'bishopMessages'})"
          variant="outline-secondary">
          <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax meet" viewBox="0 0 20.016 29">
            <g id="Pulpit" transform="translate(-104 -459.832)">
              <path id="Path_72" data-name="Path 72" d="M19.965,76.684c-.3.866-.613,1.727-.918,2.591-.084.24-.163.481-.247.719a.657.657,0,0,1-.681.5c-.208.005-.418.005-.626.007-.12,0-.163.052-.17.176-.1,1.655-.931,8.187-.974,8.709a.7.7,0,0,1-.8.733l-9.756,0c-.461,0-.923,0-1.384,0a.692.692,0,0,1-.753-.73c-.126-1.854-.916-7.713-1-8.893-.2,0-.407,0-.613,0-.556,0-.706-.1-.891-.624-.366-1.042-.7-2.094-1.088-3.125a.744.744,0,0,1,.757-1.054c2.747.016,5.494.007,8.239.007h8.671v-.007H19.2c.027,0,.052.005.075.007a.707.707,0,0,1,.694.983" transform="translate(104 398.711)" fill="#a4a7b7"/>
              <path id="Path_73" data-name="Path 73" d="M41.033,4.151A4.121,4.121,0,1,1,37.045,0a4.215,4.215,0,0,1,3.987,4.149" transform="translate(77.09 459.832)" fill="#a4a7b7"/>
              <path id="Path_74" data-name="Path 74" d="M23.773,54.328q-3.393,0-6.786,0c-.166,0-.221-.027-.188-.214a4.571,4.571,0,0,1,4.283-3.69c1.786-.029,3.572-.039,5.358,0A4.373,4.373,0,0,1,30.15,52.6a4.433,4.433,0,0,1,.607,1.482c.043.2,0,.247-.2.246q-3.393-.008-6.787,0v0" transform="translate(90.221 418.468)" fill="#a4a7b7"/>
            </g>
          </svg>

          Bishop's Message

        </b-btn>

         <div class="px-3 mb-2">
          <b-btn 
              v-if="wardinfo.show_tithing_declaration"
              @click="$router.push({name : 'tithingDeclerationSched'})"
              block
              class="btn-primary-blue tithingDecBtn">
              
              <span class="btnLabel">
                Schedule Tithing Declaration
              </span>
            </b-btn>
         </div>

        <div class="wardInfoMoreWrap">
          <div 
            class="wardInfoMoreBtns">

            <div 
              class="templeBtns">
              <b-btn 
                v-if="wardinfo.show_schedule_temple_recommend"
                @click="$router.push({name : 'templeSchedule'})"
                block
                class="btn-regular templeSchedBtn">
                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax meet" viewBox="0 0 36 21">
                  <g id="Recommend" transform="translate(-60 -544)">
                    <rect id="Rectangle_78" data-name="Rectangle 78" width="36" height="21" transform="translate(60 544)" fill="#a4a7b7"/>
                    <g id="Group_75" data-name="Group 75" transform="translate(0.204 -1)">
                      <path id="Temple" d="M.026,11.127c0-.05,0-.1,0-.149q0-3.164,0-6.328a.652.652,0,0,0-.014-.171c-.019-.071-.011-.132.107-.12.06.006.075-.026.076-.071,0-.133.019-.267.017-.4,0-.082.033-.113.125-.1C.448,3.8.5,3.77.486,3.664A2.971,2.971,0,0,1,.759,2.311c.073-.223.148-.445.227-.683a.237.237,0,0,1,.078.152,3.278,3.278,0,0,0,.253.752A2.315,2.315,0,0,1,1.51,3.655c-.012.1.028.151.154.133s.148.026.142.121c-.009.122,0,.245,0,.367,0,.054.013.093.088.085C2,4.348,2,4.406,2,4.466,2,4.81,2,5.154,2,5.5c0,.064.012.129.018.193-.049.087.014.092.089.087.131,0,.261,0,.391,0,.112.006.148-.029.147-.124,0-.573,0-1.147,0-1.72,0-.092-.035-.209.166-.153.068.019.105-.023.1-.086,0-.122,0-.245,0-.367,0-.077.03-.1.117-.1.208,0,.219,0,.206-.183A4.258,4.258,0,0,1,3.4,1.774c.088-.376.187-.75.269-1.128A3.568,3.568,0,0,1,3.844,0c.052.206.1.388.143.57.15.631.3,1.261.445,1.893a3.122,3.122,0,0,1,.014.618c0,.153,0,.156.179.148.077,0,.093.026.093.08a1.931,1.931,0,0,1,0,.309c-.021.127.063.171.175.162.129-.01.127.043.127.119,0,.577,0,1.154,0,1.731,0,.122.056.152.179.15.143,0,.287,0,.431,0,.069,0,.116-.017.127-.089a.957.957,0,0,0,.018-.147c0-.359,0-.718,0-1.078,0-.064,0-.114.1-.108.076,0,.084-.043.084-.093,0-.119.006-.237,0-.355-.006-.1.036-.134.148-.124.07.006.14.006.129-.084a3.1,3.1,0,0,1,.278-1.4c.073-.214.142-.429.217-.653a.149.149,0,0,1,.083.127,4.608,4.608,0,0,0,.307.89,1.979,1.979,0,0,1,.143.843,1.179,1.179,0,0,0,.005.172c0,.077.04.122.142.106.123-.02.156.029.148.128-.009.118,0,.237,0,.355,0,.053.017.094.089.089s.1.028.11.084A.613.613,0,0,1,7.76,4.6q0,3.188,0,6.375c0,.05,0,.1,0,.149Z" transform="translate(64.795 550)" fill="#fff"/>
                      <g id="Group_74" data-name="Group 74" transform="translate(0 2.5)">
                        <line id="Line_1" data-name="Line 1" x2="12" transform="translate(76.5 550.5)" fill="none" stroke="#fff" stroke-width="1"/>
                        <line id="Line_3" data-name="Line 3" x2="8" transform="translate(76.5 556.5)" fill="none" stroke="#fff" stroke-width="1"/>
                        <line id="Line_2" data-name="Line 2" x2="15" transform="translate(76.5 553.5)" fill="none" stroke="#fff" stroke-width="1"/>
                      </g>
                    </g>
                  </g>
                </svg>

                <span class="btnLabel">
                  Schedule Temple Recommend
                </span>
              </b-btn>
              
              <a 
                target="_blank" 
                :href="wardinfo.schedule_temple ? wardinfo.schedule_temple.value : ''" 
                class="btn btn-secondary btn-regular">
                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax meet"  viewBox="0 0 20.66 27.643">
                  <path id="Temple" d="M.068,27.643c0-.123-.009-.247-.009-.37q0-7.86,0-15.721a1.516,1.516,0,0,0-.036-.424c-.052-.177-.029-.327.285-.3.159.015.2-.063.2-.176.012-.331.051-.663.045-.993,0-.2.088-.28.333-.251.3.037.447-.043.4-.306A6.963,6.963,0,0,1,2.02,5.742c.193-.553.393-1.105.6-1.7a.576.576,0,0,1,.208.379,7.778,7.778,0,0,0,.674,1.867,5.414,5.414,0,0,1,.513,2.787c-.032.259.074.375.411.33.3-.041.395.066.377.3-.023.3,0,.608-.006.911,0,.134.035.231.234.211.275-.029.28.114.28.264,0,.854,0,1.709,0,2.563,0,.16.031.32.048.48-.129.216.037.227.237.216.347,0,.694-.01,1.04.007.3.014.393-.071.391-.309-.012-1.424-.006-2.848-.005-4.273,0-.23-.092-.52.442-.38a.2.2,0,0,0,.278-.214c0-.3.012-.608-.005-.912-.009-.19.079-.242.311-.24.554,0,.583-.007.548-.455a9.921,9.921,0,0,1,.455-3.173c.234-.934.5-1.864.715-2.8A8.4,8.4,0,0,1,10.229,0c.138.511.265.963.381,1.416.4,1.567.81,3.132,1.184,4.7a7.244,7.244,0,0,1,.037,1.535c.009.38,0,.388.476.367.2-.009.248.064.247.2a4.48,4.48,0,0,1-.01.768c-.057.315.167.425.466.4.342-.025.338.106.337.3,0,1.434.01,2.867-.01,4.3-.005.3.15.378.477.373.381-.006.764,0,1.147,0,.185,0,.308-.042.339-.22a2.228,2.228,0,0,0,.049-.364c0-.892,0-1.785,0-2.678,0-.16.01-.283.277-.268.2.012.224-.106.224-.23,0-.295.015-.589-.006-.883-.017-.239.1-.332.393-.307.187.016.373.014.342-.208a7.254,7.254,0,0,1,.739-3.477c.195-.531.378-1.065.577-1.623a.368.368,0,0,1,.22.316,10.943,10.943,0,0,0,.816,2.211,4.626,4.626,0,0,1,.38,2.093,2.735,2.735,0,0,0,.014.427c.01.19.107.3.378.263.326-.049.414.073.393.318-.024.293,0,.588-.006.883,0,.133.046.233.238.22s.254.069.292.209a1.424,1.424,0,0,1,.035.4q0,7.919,0,15.838c0,.123.005.247.007.37Z" transform="translate(0)" fill="#a4a7b7"/>
                </svg>

                <span class="btnLabel">
                  Make Temple Appointment
                </span>
              </a>
            </div>
            
            <div 
              class="scheduleTemple">
              <a
                target="_blank"
                :href="wardinfo.pay_tithes_and_offerings ? wardinfo.pay_tithes_and_offerings.value : ''" 
                class="btn btn-secondary block btn-regular">

                <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax meet"  viewBox="0 0 26.357 17.726">
                  <path id="Icon_open-envelope-closed" data-name="Icon open-envelope-closed" d="M0,2V5.191l13.178,6.381L26.357,5.191V2ZM0,6.963V19.726H26.357V6.963L13.178,13.345Z" transform="translate(0 -2)" fill="#a4a7b7"/>
                </svg>

                  Pay Tithes and Offerings
              </a>
            </div>
          </div>

          <div class="otherContact">
            <div 
                block
                @click="expandOtherContact($event, 'interview')"
                :class="['btn btn-regular', {'active' : toggledMoreInfo == 'interview'}]">

                <span class="otherBtnLabel">
                  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax meet" viewBox="0 0 22.616 21">
                  <path id="Icon_metro-user" data-name="Icon metro-user" d="M17.422,20.389V19.056a7.215,7.215,0,0,0,3.231-6c0-4.015,0-7.269-4.846-7.269S10.96,9.039,10.96,13.053a7.215,7.215,0,0,0,3.231,6v1.332c-5.48.448-9.692,3.14-9.692,6.4H27.114c0-3.255-4.213-5.948-9.692-6.4Z" transform="translate(-4.499 -5.784)" fill="#a4a7b7"/>
                </svg>

                    {{wardinfo.bishop_interview_call.value.title}}
                </span>
                
                <template v-for="(contact, index) in wardinfo.bishop_interview_call.value.contact_persons">

                  <span 
                    :key="index"
                    class="otherBtnInfo">
                    <span class="contactPosition">
                      {{ contact.position }}
                    </span>

                    <span class="contactName">
                      {{contact.name}}
                    </span>

                    <span class="contactNumber">
                      <a :href="`tel:${contact.phone_number}`">{{contact.phone_number}}</a>
                    </span>
                  </span>
                </template>

              </div>

              <div 
                 @click="expandOtherContact($event, 'babyBlessing')"
                :class="['btn btn-regular', {'active' : toggledMoreInfo == 'babyBlessing'}]">
                
                <span class="otherBtnLabel">
                  <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax meet" viewBox="0 0 18.44 24.589">
                  <path id="Icon_awesome-baby" data-name="Icon awesome-baby" d="M9.222,7.684A3.842,3.842,0,1,0,5.38,3.842,3.841,3.841,0,0,0,9.222,7.684ZM6.657,19.633,7.887,18.1,4.933,15.637l-2.2,2.757a1.925,1.925,0,0,0-.038,2.353L5,23.82a1.921,1.921,0,0,0,3.074-2.305Zm6.853-4L10.557,18.1l1.229,1.537-1.412,1.883a1.921,1.921,0,1,0,3.074,2.305l2.305-3.074a1.925,1.925,0,0,0-.038-2.353l-2.2-2.757Zm4.582-8.673a1.923,1.923,0,0,0-2.675-.471l-1.95,1.369a7.428,7.428,0,0,1-8.491,0L3.026,6.5A1.92,1.92,0,0,0,.817,9.639l1.95,1.369a11.4,11.4,0,0,0,2.613,1.34v1.484h7.684V12.352a11.4,11.4,0,0,0,2.613-1.34l1.95-1.369A1.924,1.924,0,0,0,18.092,6.964Z" transform="translate(-0.003)" fill="#a4a7b7"/>
                </svg>

                  Baby Blessing
                </span>


                <template v-for="(contact, index) in wardinfo.baby_blessing ? wardinfo.baby_blessing.value.contact_persons : []">

                  <span 
                    :key="index"
                    class="otherBtnInfo">
                    <span class="contactPosition">
                      {{ contact.position }}
                    </span>

                    <span class="contactName">
                      {{contact.name}}
                    </span>

                    <span class="contactNumber">
                      <a :href="`tel:${contact.phone_number}`">{{contact.phone_number}}</a>
                    </span>
                  </span>
                </template>

              </div>

               <div 
                block
                 @click="expandOtherContact($event, 'moveInOut')"
                :class="['btn btn-regular', {'active' : toggledMoreInfo == 'moveInOut'}]">

                  <span class="otherBtnLabel">
                    <svg xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMax meet" viewBox="0 0 24 21">
                      <path id="Icon_open-home" data-name="Icon open-home" d="M12,0,0,9H3V21H9V15h6v6h6V8.91L24,9Z" fill="#a4a7b7"/>
                    </svg>

                    Move In / Out
                  </span>

                  <template v-for="(contact, index) in wardinfo.move_membershiop_inout_call.value.contact_persons">
                    <span 
                      :key="index"
                      class="otherBtnInfo">
                      <span class="contactPosition">
                        {{ contact.position }}
                      </span>

                      <span class="contactName">
                        {{contact.name}}
                      </span>

                      <span class="contactNumber">
                        <a :href="`tel:${contact.phone_number}`">{{contact.phone_number}}</a>
                      </span>
                    </span>
                  </template>
              </div>
          </div>

       
        </div>

        <!-- <div class="templeschedule">
          <h2>Schedule Temple Recommend</h2>

          <div 
            @scroll="scrolling"
            class="dateswrapper">
            <template v-for="date in dates">
              <div 
                :key="date"
                :class="['dateitem', {'active' : activedate == date}]">
                {{date | formatdate}}
               </div>
            </template>
          </div>

          <div class="scheduletime">
            <div class="schedcol">
                <div class="timeitem ">
                  12:00 PM
                </div>

                <div class="timeitem disable">
                  12:15 PM
                </div>
                <div class="timeitem">
                  12:30 PM
                </div>
                <div class="timeitem">
                  12:45 PM
                </div>
                <div class="timeitem">
                  1:00 PM
                </div>
            </div>

            <div class="schedcol">
                <div class="timeitem disable">
                  12:00 PM
                </div>

                <div class="timeitem">
                  12:15 PM
                </div>
                <div class="timeitem">
                  12:30 PM
                </div>
                <div class="timeitem disable">
                  12:45 PM
                </div>
                <div class="timeitem">
                  1:00 PM
                </div>
            </div>
          </div>
        </div> -->

        <!-- <div class="interviewcall">
          <div class="interviewcallitem">
              <h2>{{wardinfo.bishop_interview_call.value.title}}</h2>

              <template v-if="wardinfo.bishop_interview_call.value.contact_persons">
                <template v-for="(contact, index) in wardinfo.bishop_interview_call.value.contact_persons">
                  <div 
                    :key="index"
                    class="interviewinfo">
                    <span class="name">{{contact.name}}</span>

                    <span class="contact">
                       <a :href="`tel:${contact.phone_number}`">{{contact.phone_number | VMask('(###) ###-####')}}</a>
                      </span>
                  </div>
                </template>
              </template>
          </div>

          <div class="interviewcallitem">
              <h2>{{wardinfo.move_membershiop_inout_call.value.title}}</h2>

              <template v-if="wardinfo.move_membershiop_inout_call.value.contact_persons">
                <template v-for="(contact, index) in wardinfo.move_membershiop_inout_call.value.contact_persons">
                  <div 
                    :key="index"
                    class="interviewinfo">
                    <span class="name">{{contact.name}}</span>

                    <span class="contact"> 
                       <a :href="`tel:${contact.phone_number}`">{{contact.phone_number | VMask('(###) ###-####')}}</a>
                    </span>
                  </div>
                </template>
              </template>
          </div>
        </div> -->
    
        <div class="accordion churchAccordion" role="tablist">
            <!-- <b-card 
              no-body class="mb-1">
                <b-card-header header-tag="header" class="" role="tab">
                    <div 
                      block v-b-toggle.accordion-1 
                      class="info"
                      >

                      <div class="headertitle">
                        {{wardinfo.bishopric.value.title}}
                      </div>

                      <span class="icon show">
                        <b-icon icon="chevron-up"></b-icon>
                      </span>

                      <span class="icon hide">
                        <b-icon icon="chevron-down"></b-icon>
                      </span>
                    </div>
                </b-card-header>
                <b-collapse
                    id="accordion-1"
                    accordion="my-accordion"
                    role="tabpanel"
                >
                    <b-card-body>

                      <template v-if="wardinfo.bishopric.value.contact_persons">
                        <template v-for="(contact, index) in wardinfo.bishopric.value.contact_persons">  

                          <div 
                            :key="index"
                            class="staff">
                            <div class="staffpic">
                              <template v-if="contact.profile_picture">
                                <img :src="contact.profile_picture.url" alt="">
                              </template>

                              <template v-else>
                                <span class="imageplaceholder">
                                  <b-icon icon="image"></b-icon>
                                </span>
                              </template>
                            </div>

                            <div class="staffinfo">
                              <label for="">
                                {{contact.position}}
                              </label>
                              <div class="staffname">
                                {{contact.name}}
                              </div>
                            </div>

                            <div class="staffcontact">
                              <a :href="`tel:${contact.phone_number}`">{{contact.phone_number | VMask('(###) ###-####')}}</a>
                            </div>
                          </div>
                        </template>
                      </template>


                    </b-card-body>
                </b-collapse>
            </b-card> -->

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="" role="tab">
                    <div 
                      block v-b-toggle.accordion-2
                      class="info"
                      >

                      <div class="headertitle">
                        {{wardinfo.secretaries_and_clerks.value.title}}
                      </div>

                      <span class="icon show">
                        <b-icon icon="chevron-up"></b-icon>
                      </span>

                      <span class="icon hide">
                        <b-icon icon="chevron-down"></b-icon>
                      </span>
                    </div>
                </b-card-header>
                <b-collapse
                    id="accordion-2"
                    accordion="my-accordion"
                    role="tabpanel"
                >
                    <b-card-body>
                      <template v-if="wardinfo.secretaries_and_clerks.value.contact_persons">
                          <template v-for="(contact, index) in wardinfo.secretaries_and_clerks.value.contact_persons">  

                            <div 
                              :key="index"
                              class="staff">
                              <div class="staffpic">
                                <template v-if="contact.profile_picture">
                                  <img :src="contact.profile_picture.url" alt="">
                                </template>

                                <template v-else>
                                  <span class="imageplaceholder">
                                    <b-icon icon="image"></b-icon>
                                  </span>
                                </template>
                              </div>

                              <div class="staffinfo">
                                <label for="">
                                  {{contact.position}}
                                </label>
                                <div class="staffname">
                                  {{contact.name}}
                                </div>
                              </div>

                              <div class="staffcontact">
                                <a :href="`sms:${contact.phone_number}`"><b-icon icon="chat-left-dots-fill"></b-icon></a>
                                <a :href="`tel:${contact.phone_number}`"><b-icon icon="telephone-fill"></b-icon></a>
                                 <!-- <a :href="`tel:${contact.phone_number}`">{{contact.phone_number | VMask('(###) ###-####')}}</a> -->
                                <!-- {{contact.phone_number | VMask('(###) ###-####')}} -->
                              </div>
                            </div>
                          </template>
                      </template>

                    </b-card-body>
                </b-collapse>
            </b-card>

            <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="" role="tab">
                    <div 
                      block v-b-toggle.accordion-3 
                      class="info"
                      >

                      <div class="headertitle">
                        {{wardinfo.presidents.value.title}}
                      </div>

                      <span class="icon show">
                        <b-icon icon="chevron-up"></b-icon>
                      </span>

                      <span class="icon hide">
                        <b-icon icon="chevron-down"></b-icon>
                      </span>
                    </div>
                </b-card-header>
                <b-collapse
                    id="accordion-3"
                    accordion="my-accordion"
                    role="tabpanel"
                >
                    <b-card-body>
                        <template v-if="wardinfo.presidents.value.contact_persons">
                          <template v-for="(contact, index) in wardinfo.presidents.value.contact_persons">  

                            <div 
                              :key="index"
                              class="staff">
                              <div class="staffpic">
                                <template v-if="contact.profile_picture">
                                  <img :src="contact.profile_picture.url" alt="">
                                </template>

                                <template v-else>
                                  <span class="imageplaceholder">
                                    <b-icon icon="image"></b-icon>
                                  </span>
                                </template>
                              </div>

                              <div class="staffinfo">
                                <label for="">
                                  {{contact.position}}
                                </label>
                                <div class="staffname">
                                  {{contact.name}}
                                </div>
                              </div>

                              <div class="staffcontact">
                                <a :href="`sms:${contact.phone_number}`"><b-icon icon="chat-left-dots-fill"></b-icon></a>
                                <a :href="`tel:${contact.phone_number}`"><b-icon icon="telephone-fill"></b-icon></a>

                                 <!-- <a :href="`tel:${contact.phone_number}`">{{contact.phone_number | VMask('(###) ###-####')}}</a> -->
                                <!-- {{contact.phone_number | VMask('(###) ###-####')}} -->
                              </div>
                            </div>
                          </template>
                      </template>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>
    </div>
</template>

<script>
/* Declerations */

// plugins
// import moment from 'moment'
// import _ from 'lodash'

//mixins
import { mapmixins } from "@/mixins/mapmixins";
import { calendarMixins } from "@/mixins/calendarMixins";

// Helper
import API from '@/helpers/fetch'

export default {
    mixins: [mapmixins, calendarMixins],
    name: "HomeView",
    data() {
      return {
        wardinfo       : null,
        toggledMoreInfo: null
      }
    },
    computed : {
      /* Development status */
      isDevelopment() {return process.env.NODE_ENV == 'development' ? true : false}
      // isDevelopment() {return  false}
    },
    methods : {
      /* Expand other contact */
      expandOtherContact(event, contact) {
        
        this.toggledMoreInfo = this.toggledMoreInfo == contact ? null : contact

        setTimeout(() => {
            const container            = document.querySelector('.otherContact')
            //       container.scrollLeft = ((event.screenX + event.offsetX) / 2) - (container.scrollWidth - container.offsetWidth)
            // console.log([container], 'event', event)

            // event.target.scrollIntoView({inline : 'center'})

            // const elRect = event.target.getBoundingClientRect()
            // const elLeft = elRect.left + container.offsetLeft
            // const middle = elLeft - (container.clientWidth / 2)

            // console.log([container], 'scrollTo',container.scrollLeft - middle)

            // container.scrollLeft  =  middle


            const allElements = Array.from(document.querySelectorAll('.otherContact .btn'))
            const expectedElements = allElements.slice(0, allElements.findIndex(element => element.classList.contains('active')))
            
            const offset = allElements.length == (expectedElements.length + 1) ? 30 : 15
            console.log(offset, allElements.length, (expectedElements.length + 1), 'expectedElements', expectedElements)
            container.scrollLeft = expectedElements.reduce((subtotal, el) => subtotal + el.offsetWidth, 0) + offset
        }, 0);
      },
      /* Get ward info content */
      async getwardinfo() {
        const url = `churchapp/v1/page/ward-info`
        const res = await API.fetch({
          url   : url,
          method: "GET"
        })

       this.wardinfo = res
      },
     
    },
    async beforeMount( ){
        // Get ward info
      await this.getwardinfo()

       // Get ward calendar
       await this.getwardcalendar();

      /* Stake calendar */
      await this.getStakeCalendar();
    }
};
</script>

<style lang="scss">
@import "@/scss/partials/_colors.scss";
@import "@/scss/partials/_breakpoints.scss";

.wardinfowrapper {
    
    .tithingDecBtn {
      background-color: $blue;
      border-color: $blue;
      font-weight: bold;
      height : 3.528rem;

      &:hover, &:focus, &:active {
        background-color: transparent !important;
        color           : $blue !important;
        border-color: $blue !important;
      }
    }

    h1 {
        font-size    : 21px;
        font-weight  : 600;
        color        : $gray;
        margin-bottom: 15px;
    }

    h2 {
      font-size  : 18px;
      color      : $black;
      font-weight: 600;
    }

    label {
        color: $gray1;
        font-size: 15px;
        margin-bottom: 0px;
    }


    .welcomewrapper {
      padding-left: 30px;
      padding-right: 30px;
    }

    .bishopMessageBtn {
      width        : calc(100vw - 30px);
      margin-inline: auto;
      margin-block : 20px 15px;
      height       : 56px;
      border-color : $gray7;
      font-size    : 15px;
      font-weight  : 600;
      color        : $black;

      svg {
        width       : 20px;
        height      : auto;
        margin-right: 10px;
      }

      &.btn-outline-secondary  {
          &:hover, &:focus, &:active {
            background-color: transparent !important;
            border-color    : $gray7 !important;
            color           : $black !important;
            box-shadow      : none !important;
          }
      }
    }

    .churchAccordion {
      margin-top : 0;
    }

    
    
    .wardInfoMoreWrap {


      .wardInfoMoreBtns {
        padding-inline: 15px;

        .templeBtns {
          display: flex;

          .btn {
            flex     : 1;
            font-size: 14px;
            color    : $black;


           
            &:only-child {
              flex-direction: row;
              margin-inline : 0 !important;
              position      : relative;
              top           : -2px;
              line-height   : 2;

              .btnLabel {
                padding-left: 8px;
                padding-top : 10px;
              }
            }

            &:not(:only-child) {
              display        : flex;
              flex-direction : column;
              justify-content: center;
              align-items    : center;
              line-height    : 1;

              svg {
                margin-bottom: 8px;;
              }
            }

            &:first-child {
              margin-right : 7.5px;
            }

            &:last-child {
              margin-left: 7.5px;
            }

            &.templeSchedBtn {
                svg {
                  width        : 35px;
                  height       : 35px;
                  margin-top   : -15px;
                  margin-bottom: 12px;
                }
            }

            &:hover {
              color : white;

              svg {
                color : white;
              }
            }
          }
        }
      }

      .scheduleTemple {
        .btn {
          svg {
            width       : 28px;
            height      : 28px;
            margin-top  : -15px;
            margin-right: 10px;
          }
          
          // &:hover, &:focus, &:active {
          //   background-color: transparent !important;
          //   border-color    : $gray7 !important;
          //   color           : $black !important;
          //   box-shadow      : none !important;
          // }
        }
      }

      

      .btn {
        margin-bottom: 8px;

        svg {
          width : 28px;
          height: 28px;
        }

        &.block {
          display: block;
        }

        &:focus {
          outline   : none;
          box-shadow: none;
        }

        &.hover {
          color : white;
        }

        
      }

      .otherContact {
        display       : flex;
        align-items   : center;
        width         : 100vw;
        overflow      : auto hidden;
        padding-inline: 15px;

        &:has(.btn:last-child:focus) {
          justify-content: flex-end;
        }


      .btn {
            height: 100%;
            flex        : 1;
            margin-right: 12px;
            box-shadow  : none;

            .b-icon.bi {
              margin-bottom: 10px;
              color        : $gray6;
            }

           



          &:last-child {
            margin-right : 0;

          
          }

           .otherBtnInfo {
              display      : none;
              border-right : 1px solid $gray1;
              padding-right: 15px;

              &:last-child {
                border-right : none;
              }
           }

           .otherBtnLabel {
              display        : flex;
              flex-direction : column;
              justify-content: center;
              align-items    : center;
              line-height    : 1.2;
              font-size      : 14px;

              svg {
                width        : 24px;
                height       : 24px;
                margin-bottom: 10px;
              }
           }

           &:hover, &:focus, &:active, &.active {
            background-color: $gray2;
            border-color    : $gray2;
            color           : $black;
            outline         : none;
            box-shadow      : none !important;
           }

        


           &.active {
            display         : flex;
            align-items     : center;

            .otherBtnLabel {
              white-space    : nowrap;
            }

            .otherBtnInfo {
              display       : flex;
              flex-direction: column;
              padding-left  : 15px;
              white-space   : nowrap;
              text-align    : left;

              .contactPosition {
                font-size  : 14px;
                font-weight: 400;
                color      : $gray1;
              }

              .contactName {
                font-size :15px;
              }

              .contactNumber {
                @extend .contactName;

                a {
                  color : $gray1;
                }
              }
            }
           }
          }
        
      }

    }


   


    .bishopricWrapper {
      margin-top    : 15px;
      padding-inline: 15px;

      .personCard {
        padding-inline: 5px;

       
      }

      .sectionTitle {
        padding-inline: 15px;
      }
    }



  

    .interviewcall {
      padding-left    : 30px;
      padding-right   : 30px;
      padding-bottom  : 30px;
      padding-top     : 30px;
      background-color: $gray2;

      .interviewcallitem {
        margin-bottom : 30px;

        .interviewinfo {
          display      : flex;
          align-items  : center;
          font-size    : 15px;
          margin-bottom: 15px;

          .name  {
            flex : 1;
            color: $black;
            padding-right : 15px;
          }

          .contact {
            color      : $gray1;
            font-weight: 600;

            a {
              color      : inherit;
              font-weight: inherit;
            }
          }
        }
      }
    }

   
}
</style>
