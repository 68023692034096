// Declerations
// Helpers
import Helper from '@/helpers/helpers'

export const mapmixins = {
    methods : {
         /**
         * Open map
         * @param {*} type 
         * @param {*} address 
         */
          openMap(type ='point', data = {address : '', lat : '', lng : ''}) {

            const isDesktop = window.innerWidth > 1360 ? true : false;
            // const lat       = parseFloat(data.lat)
            // const lng       = parseFloat(data.lng)

            if(type == 'drive') {

                
                const url = `https://www.google.com/maps/dir/?api=1&destination=${data.address}&travelmode=driving`
                if(isDesktop) {
                    window.open(encodeURI(url));
                } else {
                    /**
                     * Check device if iOS or Andriod
                     */
                    if(Helper.getdevicetype('device') == 'iOS') {
                        // console.log(`maps://?q=${lat},${lng}`)
                        window.open(`maps://?q=${data.address}`)
                    } else {
                        
                        // window.location = `google.navigation:q=${lat},${lng}&mode=d`
                        window.open(url)
                    }
                }
            } else {
                const url = `https://www.google.com/maps/search/?api=1&query=${data.address}`
                // if(isDesktop) {
                //     window.open(encodeURI(url));
                // } else {
                //     window.location = `geo:${lat},${lng}?q=${data.address}`
                // }
                
                console.log('addres map', Helper.getdevicetype('device'))

                if(Helper.getdevicetype('device') == 'iOS') {
                    // console.log(`maps://?q=${lat},${lng}`)
                    window.open(`maps://?q=${data.address}`)
                } else {
                    // window.location = `google.navigation:q=${lat},${lng}&mode=d`
                    window.open(url)
                }
            }
            // window.location =  'https://www.google.com/maps/dir/?api=1&destination=14.625094215984506,121.05768642828393&mode=d'
        },
        
    }
}