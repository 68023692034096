<template>
  <div 
    :class="[browserType, `${$route.name}-app`]"
    id="app">
     <b-nav 
        fill
        v-if="showMenu"
        class="mainnav"
        >

        <div class="swiper swiper-menu">
          <div class="swiper-wrapper">
            <div 
              v-for="menu in menus"
              :key="menu.id"
              @click="$router.push({name : menu.slug})" 
              :class="['swiper-slide', {'active' : $route.name == menu.slug}]">
              {{menu.menutitle}}
            </div>
          </div>
        </div>

          <!-- <b-nav-item 
            v-for="menu in menus"
            :key="menu.id"
            @click="$router.push({name : menu.slug})" 
            :active="$route.name == menu.slug">{{menu.menutitle}}</b-nav-item> -->

        <!-- <b-nav-item 
            @click="$router.push({name : 'sundayprogram'})" 
            :active="$route.name == 'sundayprogram'">Sunday Program</b-nav-item>

        <b-nav-item 
            @click="$router.push({name : 'wardcalendar'})" 
            :active="$route.name == 'wardcalendar'">Ward Calendar</b-nav-item> -->

          
      </b-nav>
    <router-view/>
  </div>
</template>

<script>
/* Decleration */
// Helper
import API from '@/helpers/fetch'
import Helper from '@/helpers/helpers'

/* Mixins */
import { notificationMixins } from './mixins/notificationMixins';
import { crontabMixins } from './mixins/crontabMixins';

/* Plugins */
import PullToRefresh from 'pulltorefreshjs';
import _ from 'lodash'
import Swiper from 'swiper';


export default {
    mixins : [crontabMixins, notificationMixins],
    data() {
      return {
        menus      : [],
        pullrefresh: null,
        swiperMenu : null
      }
    },
    computed : {
      /* Device type */
      browserType() {
        const device = Helper.getdevicetype('broswer')

        return device ? device : null
      },
      /* Show / hide top menu */
      showMenu () {
        let show = true

        if(this.$route.name == 'templeSchedule') show = false

        if(this.$route.name == 'staketempleschedule') show = false

        if(this.$route.name == 'stakeConf') show = false

        if(this.$route.name == 'login') show = false

        if(this.$route.name == 'bookSchedule') show = false

        if(this.$route.name == 'bookStakeSchedule') show = false

        if(this.$route.name == 'bishopMessages') show = false

        if(this.$route.name == 'tithingDeclerationSched') show = false

        if(this.$route.name == 'bookScheduleTithing') show = false
        
        if(this.$route.name == 'cancelBookingTithing') show = false

        if(this.$route.name == 'bookScheduleTithing') show = false

        if(this.$route.name == 'successBookingTemple') show = false
        
        if(this.$route.name == 'successTithing') show = false
        
        if(this.$route.name == 'successStake') show = false

        if(this.$route.name == 'reservedscheduleTemple') show = false

        if(this.$route.name == 'reservedscheduleTithing') show = false
        
        if(this.$route.name == 'reservedscheduleStake') show = false

        return show
      }
    },
    watch : {
      /* watch showMenu to reinit slider */
      showMenu(newState, oldState) {
        if(oldState == false && newState) {
           /* Re init menu slider */
           setTimeout(() => {
            this.initMenuSlider()
          }, 0);
        }
      },
      /* Watch route  */
      $route : {
        handler(newR, oldR){
          /* Get menu  */
          /* Prevent if old route and new route group is same */
          if(!_.isEqual(newR.meta.group, oldR.meta.group)) {
            this.getmenus()
          }
        },
        deep : true
      }
    },
    methods : {
      /* Init menu slider */
      initMenuSlider() {
        if(this.swiperMenu) {
          try {
            this.swiperMenu.destroy()
          } catch (error) {
              console.log('error updating menu')
          }
        } 

        this.swiperMenu = new Swiper('.swiper-menu', {
          slidesPerView: 'auto',
          spaceBetween : 20,
          // loop         : true,
        })
      },
      /* Init pull refresh  */
      initpullrefresh() {
        /* Prevent if not ios */
        if(Helper.getdevicetype() !== 'iOS') return

        this.pullrefresh = PullToRefresh.init({
          mainElement: 'body',
          distIgnore : 100,
          onRefresh() {
            window.location.reload();
          }
        });

      },
      /* Get menus*/
      async getmenus() {
        // console.log('route', this.$route)

        /* Reset menus */
        this.menus = []
        
        /* Get menu for geneva  */
        /* Prevent if no route */
        if(!this.$route.name) return

        if(this.$route.meta.group.includes('geneva')) {
          /* Temporary defince geneva menus  */

            this.menus = [
              {
                id  : Helper.makeString(5),
                slug: 'directory',
                menutitle : 'Directory'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'wards',
                menutitle: 'Wards'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'handbook',
                menutitle: 'Handbook'
              },
              {
                id       : Helper.makeString(5),
                slug     : 'stakeCalendar',
                menutitle: 'Stake Calendar'
              }
            ]

            /* Init menu slider */
            this.initMenuSlider()

            return
        }

        /* Get menu for public */
        if(this.$route.meta.group.includes('public')) {
          

          const pages = await API.fetch({
              // url   : 'wp/v2/pages?orderby=date&order=asc',
              url   : 'churchapp/v1/active_pages',
              method: 'GET'
          }) 
  
            /* Loop slug to get custom field for title */


            this.menus = pages.map(p => {

              return {
                id        : Helper.makeString(5),
                slug      : p.page_slug,
                menutitle : p.menu_title,
                menu_order: p.menu_order
              }
            }).sort((a, b) => a.menu_order - b.menu_order)

            /* Init menu slider */
            this.initMenuSlider()
          
        }

      }
    },
    beforeMount() {
      // /* Init notifications */
      // this.initNotif()

      // /* Init crontab */
      // this.initCron()
    },
    mounted () {
      /* Get menus */
      this.getmenus();
      /* Init pull refresh */
      this.initpullrefresh()
    }
}
</script>

<style lang="scss">
@import '@/scss/partials/_colors.scss';
@import '@/scss/partials/_breakpoints.scss';

body {
  &::-webkit-scrollbar {
    width : 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: #21242a;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
    cursor: pointer;
  }

  * {
    &::-webkit-scrollbar {
      width : 0;
      height: 0;
    }

    &::-webkit-scrollbar-track {
      background-color: #21242a;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
      cursor: pointer;
    }
  }
}

.ptr--ptr {
  background-color: $gray2;
  color           : $black;

  .ptr--icon, .ptr--text {
    color : inherit;
  }
}

#app {
  font-family            : Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing : antialiased;
  -moz-osx-font-smoothing: grayscale;
  color                  : #2c3e50;
  font-weight            : 500;

  &.Safari {
    .calendarEvents .calendareventwrapper .events .eventitem.stake .eventinfo .cat {
        padding-top : 5px;
    }
  }
}

.nav {
  &.mainnav {
    white-space   : nowrap;
    // padding-inline: 30px;
    display       : flex;
    // width         : 100vw;
    // overflow      : auto hidden;
    flex-wrap     : nowrap;

    .swiper {
      padding-inline: 30px;
      width         : 100vw;

      .swiper-wrapper {
        .swiper-slide {
          flex     : 1;
          // width         : auto;
          color         : $gray1;
          font-size     : 15px;
          padding-inline: 0;
          padding-block : 30px;
          // margin-right  : 20px;

      

        &.active {
          color      : $blue;
          position   : relative;
          font-weight: 600;
          
          
          &::after {
            content      : '';
            border-bottom: 1.5px solid $blue;
            position     : absolute;
            bottom       : 30px;
            width        : 60%;
            height       : 2px;
            left         : 50%;
            right        : 0;
            transform    : translateX(-50%);
          }
        }
        }
      }
    }

    .nav-item {
      margin-right : 20px;

       &:last-child {
        margin-right : 0;
      }
    }

    .nav-link {
      color         : $gray1;
      font-size     : 15px;
      padding-inline: 0;
      padding-block : 30px;

     

      &.active {
        color      : $blue;
        position   : relative;
        font-weight: 600;
        
        
        &::after {
          content      : '';
          border-bottom: 1.5px solid $blue;
          position     : absolute;
          bottom       : 30px;
          width        : 60%;
          height       : 2px;
          left         : 50%;
          right        : 0;
          transform    : translateX(-50%);
        }
      }
    }
  }
}

.pageTitle {
  font-size    : 21px;
  font-weight  : 600;
  margin-bottom: 0;
}

.sectionTitle {
  font-size  : 18px;
  font-weight: 600;
  color      : $black;
}

.collapseSection {
  padding   : 15px;
  border-top: 1px solid $gray5;

  a {
    display    : flex;
    align-items: center;

    .collapseLabel {
      @extend .sectionTitle;
      flex : 1;
    }

    .b-icon.bi {
      color : $black;
    }
  }

  
}

.btn {
  &.btn-secondary {
    background-color: $black;
  }

  &.btn-regular {
    background-color: $gray2;
    color           : $black;
    border-color    : $gray2;
    font-weight     : 600;
    padding         : 15px;


    &:hover, &:focus, &:active {
      background-color: $gray2 !important;
      border-color    : $gray2 !important;
      color           : $black !important;
      box-shadow      : none !important;
    }
  }

  

}

.backBtn {
  color    : $black;
  font-size: 20px;
}

.tabs {
  .nav-tabs {
    border-bottom : none;

    .nav-item {
      .nav-link {
        color        : $gray1;
        font-weight  : 400;
        font-size    : 13px;
        border-color: transparent;

        &.active {
          
          font-weight: 600;
          color      : $black;
          position   : relative;

          &::after {
            border-bottom: 1px solid $black;
            content      : '';
            display      : block;
            position     : absolute;
            bottom       : 0;
            width        : calc(100% - 30px);
          }
        }
      }
    }
  }

  .tab-content {
    margin-top: 0;
    padding   : 15px;
  }
}

.addressItem {
  .label {
    font-size  : 15px;
    font-weight: 400;
  }

  .address {
          margin-bottom: 15px;
          display      : flex;
  
          .theaddress {
              flex         : 1;
              color        : $blue;
              padding-right: 5px;
              font-size    : 15px;
              font-weight  : 400;
          }
  
          .markericon {
              margin-right: 5px;
              color: $blue;
          }
  
          a {
              g {
                  svg {
                      &:last-child {
                          color: white;
                      }
  
                      &:first-child {
                          color: $gray1;
                      }
                  }
              }
          }
      }
}



.nameSection {
  margin-top   : 5px;
  display      : flex;
  margin-bottom: 15px;

  .nameLabel {
    font-size   : 15px;
    margin-right: 10px;
    color       : $gray1;
    white-space: nowrap;
  }

  .nameValue {
    font-size  : 15px;
    font-weight: 600;
    color      : $black;
  }


}

 .personCard {
      text-align: center;

      img {
          width          : 76px;
          height         : 76px;
          overflow       : hidden;
          border-radius  : 100%;
          object-fit     : cover;
          object-position: top;
          margin-bottom  : 5px;
      }

      .b-icon.bi {
          font-size : 76px;
      }

      .personCardName {
          font-size  : 14px;
          font-weight: 600;
      }

      .personCardPosition {
          @extend .personCardName;

          color      : $gray1;
          
      }
      
  }
  

.hymm {
    background-color: #fff;
    display         : flex;
    align-items     : center;
    padding         : 10px;
    margin-top      : 20px;
    margin-bottom   : 20px;

    svg {
        font-size   : 30px;
        color       : $gray4;
    }

    .hymminfo {
        flex      : 1;
        text-align: center;

        .hymmtitle {
            font-weight : 600;
            padding-left: 15px;

            a {
              color : $blue;
            }
        }

        p {
            margin-bottom: 0;
            color        : $gray1;
            font-size    : 13px;
            font-weight  : 500;
            
        }
    }
}

.churchAccordion {
  margin-top   : 30px;
  padding-left : 30px;
  padding-right: 30px;

  .card {
    border-left  : none;
    border-right : none;
    border-top: 1px solid $gray3;

    &:first-child {
      border-top: 0;
    }
    
    &:last-child {
      border-bottom : 0;
    }

  }

  .card-body {
    padding-left : 0;
    padding-right: 0;
  }

  header {
    &.card-header {
      background-color: transparent;
      border-color    : transparent;
      padding         : 15px 0;

      & > div {
        display    : flex;
        align-items: center;

        &.not-collapsed {
          .hide {
            display: none;
          }
        }

        &.collapsed {
          .show {
            display: none;
          }
        }
      }

      .headertitle {
        flex       : 1;
        font-weight: 600;
        color      : $black;
        font-size  : 18px;
      }

    }
  }
  
  .staff {
    display      : flex;
    align-items  : center;
    margin-bottom: 15px;

    .staffpic {
      flex         : 0 0 40px;
      height       : 40px;
      border-radius: 100%;
      overflow     : hidden;
      margin-right : 10px;
      border       : 1px solid $gray3;


      img {
        width     : 100%;
        height    : 100%;
        object-fit: cover;
      }

      .imageplaceholder {
        width          : 100%;
        height         : 100%;
        display        : flex;
        flex           : 1;
        align-items    : center;
        justify-content: center;

        .b-icon.bi {
          color : $gray3;
        }
      }
    }

    .staffinfo {
      flex : 1;

      label {
        font-weight  : 600;
        font-size    : 14px;
        color        : $gray1;
        margin-bottom: 0;
      }

      .staffname {
        font-size  : 15px;
        color      : $black;
        font-weight: 600;
      }
    }

    .staffcontact {
      color      : $gray1;
      font-size  : 15px;
      font-weight: 600;
      display    : flex;
      align-items: center;

      a {
        color       : inherit;
        font-size   : inherit;
        font-weight : inherit;
        margin-right: 15px;

        .bi {
          font-size: 20px;
          color    : $gray6;
        }
      }
    }
  }
}

.calendarEvents{
    padding         : 0 30px 30px;
    background-color: $gray2;
    margin-top      : -90px;
    min-height      : 100vh;

    h1 {
        font-size       : 21px;
        margin-bottom   : 0;
        padding-bottom  : 30px;
        padding-top     : 90px;
        font-weight     : 600;
        position        : sticky;
        z-index         : 150;
        top             : 0;
        background-color: $gray2;
    }

    .calendareventwrapper {
      padding-bottom : 150px;

        h2 {
          font-size       : 20px;
          font-weight     : 600;
          position        : sticky;
          top             : 145px;
          z-index         : 100;
          background-color: $gray2;
          margin-bottom   : 0;
          padding-bottom  : 10px;

            .year {
                color    : $gray1;
                font-size: 18px;
            }
        }


        .eventsectionitem {
            // margin-top : 30px;
        }

        .events {
            .eventitem {
                display      : flex;
                align-items  : center;
                margin-bottom: 20px;

                &.stake {
                  .eventinfo {
                      .cat {
                          background-color: $blue;
                          padding         : 2px 8px;
                          padding-top     : 3.5px;
                          color           : white;
                          border-radius   : 50px;
                          width           : fit-content;
                          line-height     : 0;
                          display         : flex;
                          align-items     : center;
                          height          : 23px;
                          vertical-align  : middle;
                          margin-bottom   : 4px;
                      }
                  }
              }

                .date {
                    padding-left : 20px;
                    padding-right: 20px;
                    font-weight  : 600;
                    text-align   : center;
                    flex         : 0 0 69px;
                    width        : 69px;

                    .dayName {
                      font-size    : 14px;
                    }
                }

                .eventinfo {
                    background-color: #fff;
                    padding         : 15px;
                    flex            : 1;
                    position        : relative;

                    .remindBtn {
                      position: absolute;
                      top     : 10px;
                      right   : 10px;
                      color   : $gray6;

                      .church-icon {
                        width : 20px;
                        height: 20px;
                      }
                    }

                    .cat {
                        color      : $blue;
                        font-size  : 12px;
                        font-weight: 600;
                    }

                    .eventname {
                        font-weight: 600;
                        font-size  : 15px;
                    }

                    .eventtime {
                        font-size: 15px;
                        color    : $gray1;

                        .eventSTime, .eventETime {
                          text-transform: uppercase;
                        }
                    }

                    .eventdesc {
                        @extend .eventtime;
                        font-size: 12px;


                        a {
                          padding-left : 2px;
                          word-break: break-all;
                        }
                    }
                }
            }
        }
    }
}
</style>

