import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
      globalStakeCalendar: null,
      globalWardCalendar : null,
      enableNotification : false,
      reminders          : []
  },
  getters: {

  },
  mutations: {
    /* Update reminders */
    updateReminders(state, payload) {
      state.reminders = payload
    },
    /* Update notification state */
    updateNotificationState(state, payload) {
      state.enableNotification = payload
    },
    /* Update stake calendar */
    updateStakeCalendar(state, payload) {
      state.globalStakeCalendar = payload
    },
    /* Update ward calendar */
    updateWardCalendar(state, payload) {
      state.globalWardCalendar = payload
    }
  },
  actions: {
  },
  modules: {
  }
})
