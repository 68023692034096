<template>
    <div class="church-icon">
        <svg
            v-if="icon == 'file'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM4.5 9a.5.5 0 0 1 0-1h7a.5.5 0 0 1 0 1h-7zM4 10.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 1 0-1h4a.5.5 0 0 1 0 1h-4z"
                ></path>
            </g>
        </svg>

        <svg
            v-if="icon == 'card'"
            preserveAspectRatio="xMidYMax meet"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 21"
        >
            <g id="Recommend" transform="translate(-60 -544)">
                <rect
                    id="Rectangle_78"
                    data-name="Rectangle 78"
                    width="36"
                    height="21"
                    transform="translate(60 544)"
                    fill="currentColor"
                />
                <g
                    id="Group_75"
                    data-name="Group 75"
                    transform="translate(0.204 -1)"
                >
                    <path
                        id="Temple"
                        d="M.026,11.127c0-.05,0-.1,0-.149q0-3.164,0-6.328a.652.652,0,0,0-.014-.171c-.019-.071-.011-.132.107-.12.06.006.075-.026.076-.071,0-.133.019-.267.017-.4,0-.082.033-.113.125-.1C.448,3.8.5,3.77.486,3.664A2.971,2.971,0,0,1,.759,2.311c.073-.223.148-.445.227-.683a.237.237,0,0,1,.078.152,3.278,3.278,0,0,0,.253.752A2.315,2.315,0,0,1,1.51,3.655c-.012.1.028.151.154.133s.148.026.142.121c-.009.122,0,.245,0,.367,0,.054.013.093.088.085C2,4.348,2,4.406,2,4.466,2,4.81,2,5.154,2,5.5c0,.064.012.129.018.193-.049.087.014.092.089.087.131,0,.261,0,.391,0,.112.006.148-.029.147-.124,0-.573,0-1.147,0-1.72,0-.092-.035-.209.166-.153.068.019.105-.023.1-.086,0-.122,0-.245,0-.367,0-.077.03-.1.117-.1.208,0,.219,0,.206-.183A4.258,4.258,0,0,1,3.4,1.774c.088-.376.187-.75.269-1.128A3.568,3.568,0,0,1,3.844,0c.052.206.1.388.143.57.15.631.3,1.261.445,1.893a3.122,3.122,0,0,1,.014.618c0,.153,0,.156.179.148.077,0,.093.026.093.08a1.931,1.931,0,0,1,0,.309c-.021.127.063.171.175.162.129-.01.127.043.127.119,0,.577,0,1.154,0,1.731,0,.122.056.152.179.15.143,0,.287,0,.431,0,.069,0,.116-.017.127-.089a.957.957,0,0,0,.018-.147c0-.359,0-.718,0-1.078,0-.064,0-.114.1-.108.076,0,.084-.043.084-.093,0-.119.006-.237,0-.355-.006-.1.036-.134.148-.124.07.006.14.006.129-.084a3.1,3.1,0,0,1,.278-1.4c.073-.214.142-.429.217-.653a.149.149,0,0,1,.083.127,4.608,4.608,0,0,0,.307.89,1.979,1.979,0,0,1,.143.843,1.179,1.179,0,0,0,.005.172c0,.077.04.122.142.106.123-.02.156.029.148.128-.009.118,0,.237,0,.355,0,.053.017.094.089.089s.1.028.11.084A.613.613,0,0,1,7.76,4.6q0,3.188,0,6.375c0,.05,0,.1,0,.149Z"
                        transform="translate(64.795 550)"
                        fill="#fff"
                    />
                    <g
                        id="Group_74"
                        data-name="Group 74"
                        transform="translate(0 2.5)"
                    >
                        <line
                            id="Line_1"
                            data-name="Line 1"
                            x2="12"
                            transform="translate(76.5 550.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                        />
                        <line
                            id="Line_3"
                            data-name="Line 3"
                            x2="8"
                            transform="translate(76.5 556.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                        />
                        <line
                            id="Line_2"
                            data-name="Line 2"
                            x2="15"
                            transform="translate(76.5 553.5)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                        />
                    </g>
                </g>
            </g>
        </svg>

        <svg
            v-if="icon == 'bell'"
            preserveAspectRatio="xMidYMax meet"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
        >
            <g data-v-41be6633="">
                <path
                    d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"
                ></path>
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            default: () => null,
        },
    },
};
</script>

<style lang="scss">
.church-icon {
    svg {
        width: inherit !important;
        height: inherit !important;
        color: inherit;
    }
}
</style>
