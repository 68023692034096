export default class Api {
    static cacheName = "churchapp-apis";

    /**
     * Delete cache
     * @param {String} url cache url to delete
     */
    static async deleteCache(url) {
        const cache = await caches.open(this.cacheName);

        await cache.delete(url, {ignoreSearch : true});
    }

    /**
     * Fetch request
     * @param {String} url request url
     * @param {Object} params Body data
     * @param {String} method request method
     * @param {String} baseUrl request domain
     * @param {Boolean} isCache return reponse from cache
     */
    static async fetch({
        url,
        params,
        method,
        baseUrl = null,
        isCache = true
    }) {
        /* Process options */
       const options = {
        method : method,
       }
        /* 
            Process params
        */
       const baseurl = baseUrl ? baseUrl : process.env.VUE_APP_APIBASEURL
       const newparams = new FormData()

       if(params) {
            for (const key in params) {
                const value = params[key];

                newparams.append(key, value)
            }

            options.body = newparams
       }

       /**
        * Process call cache first or network first
        */
       const cache = await caches.open(this.cacheName);

        // Process call
        const res = fetch(`${baseurl}/${url}`, options).then( async (networkResponse) => {
            if (networkResponse.ok) {
                cache.put(url, networkResponse.clone());
            }
            return networkResponse;
        });

        if(!isCache)  {
            const result = (await res);
            return await result.json();
        }

        /* only get method will return cache */

        if(method != 'GET') {
            const result = (await res);
            return await result.json();
        }

        const result    = (await cache.match(url)) || (await res);
        const returning = await result.json()

        return returning
    }
}